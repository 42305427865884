/* Event Section */
.events-container44 {
  background-image: url('https://i.postimg.cc/j2ZV2zXJ/PC-25-web-banner.png');
  background-size: cover;
  background-position: center;
  background-attachment: fixed; /* This will make the background fixed while content scrolls */
  padding: 50px 20px;
  color: #d77f7f; /* Light text color to match general styling */
  text-align: center;
  font-family: 'Roboto Slab', serif; /* Apply the same font family */
}

.events-heading44 {
  font-size: 3rem;
  font-weight: bold;
  color: #ffd39d; /* Color from the general styling */
  font-family: Cantora One;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1); /* Add text shadow for emphasis */
  text-transform: uppercase;
  margin-bottom: 40px;
}

.event-cards-container44 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  max-width: 1200px; /* Increased max-width for larger cards */
  margin: 0 auto;
}

.event-card44 {
  background-color: #4b3d33; /* Brown background for the card */
  border: 2.5px solid #8c9c91;
  /* border: 3.5px solid #f7faf8;  */
  border-radius: 40px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  max-width: 500px; /* Increased card width */
  margin: 0 auto; /* Center the card */
}

.event-card44:hover {
  transform: translateY(-10px);
}

.event-card-img44 img {
  width: 100%;
  height: auto;
  border-bottom: 2px solid #8c9c91;
}

.event-card-content44 {
  padding: 20px; /* Increased padding */
}

.event-card-content44 h3 {
  font-size: 1.5rem; /* Increased font size */
  font-weight: bold;
  color: #f2d96f; /* Light yellow to contrast with brown */
  margin-bottom: 15px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Light text shadow */
}

.event-register-btn44 {
  display: inline-block;
  background-color: #5c4d3f; /* Darker brown */
  color: white;
  padding: 10px 20px; /* Increased padding */
  font-size: 1.2rem; /* Increased font size */
  font-weight: bold;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.event-register-btn44:hover {
  background-color: #8c7c6b; /* Lighter brown on hover */
}

@media screen and (max-width: 768px) {
  .event-cards-container44 {
    grid-template-columns: 1fr;
  }
  .event-card44 {
    max-width: 90%; /* Keep cards smaller on mobile */
  }
}

/* Add styles for the new section */
.register-section44 {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.register-section44 label {
  margin-bottom: 5px;
  font-family: 'Roboto Slab', serif;
  font-size: 1rem;
  color: #f4f4f4; /* Consistent text color */
}

.register-section44 input {
  padding: 10px;
  margin-bottom: 10px;
  width: 80%;
  max-width: 400px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.register-button44 {
  padding: 10px 20px;
  background-color: #5c4d3f; /* Darker brown background for the register button */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.register-button44:hover {
  background-color: #8c7c6b; /* Lighter brown on hover */
}

.register-button44:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}


/* Popup styling */
.event-description-popup44 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content44 {
  background-color: #f0e1cd ; /* Light yellow background */
  color: #4b3d33; /* Dark brown text */
  padding: 20px;
  font-family: 'Roboto', sans-serif;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.popup-content44 h3 {
  font-size: 2rem;
  color: #4b3d33; /* Dark brown */
}

.popup-content44 p {
  font-size: 1rem;
  color: #4b3d33; /* Dark brown */
  margin-top: 10px;
}

.close-popup-btn44 {
  background-color: #5c4d3f; /* Dark brown */
  color: white;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s;
}

.close-popup-btn44:hover {
  background-color: #8c7c6b; /* Lighter brown on hover */
}


/* General styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbarPPC {
  position: fixed;
  top: 0;
  width: 99%;
  height: 60px;
  background: rgba(0, 0, 0, 0.4); 
  backdrop-filter: blur(5px); 
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  padding: 0 20px;
}

.navbar-containerPPC {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.navbar-logoPPC img {
  height: 40px;
}

.navbar-linksPPC {
  display: flex;
  gap: 25px;
  transition: all 0.3s ease-in-out;
  margin-top: 13px;
  opacity: 0.6;
}

.navbar-linksPPC ul {
  display: flex;
  gap: 35px;
  list-style: none;
}

.navbar-linksPPC li a {
  text-decoration: none;
  color: white;
  font-size: 16px;
  font-weight: 500;
  transition: color 0.2s;
}

.navbar-linksPPC li a:hover {
  color: #ffdd00; /* Highlight on hover */
}

/* Toggle menu for mobile */
.navbar-togglePPC {
  display: none;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
}

.navbar-togglePPC .toggle-barPPC {
  width: 25px;
  height: 3px;
  background: white;
  border-radius: 2px;
}

/* Responsive styles */
@media (max-width: 1023px) {
  .navbar-linksPPC {
    position: fixed;
    top: 60px;
    right: 0;
    width: 200px;
    height: calc(100vh - 60px);
    background: rgba(0, 0, 0, 0.9);
    flex-direction: column;
    padding: 20px;
    gap: 15px;
    transform: translateX(100%);
    opacity: 0;
    pointer-events: none;
  }

  .navbar-linksPPC.openPPC {
    transform: translateX(0);
    opacity: 1;
    pointer-events: auto;
  }

  .navbar-linksPPC ul {
    flex-direction: column;
  }

  .navbar-togglePPC {
    display: flex;
  }
}

@media (max-width: 767px) {
  .navbar-logoPPC img {
    height: 30px;
  }

  .navbar-linksPPC {
    width: 180px;
    gap: 10px;
  }
}

/* General styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.home22 {
  overflow: hidden;
}

.background-video-container22 {
  position: relative;
  width: 100%;
  height: 100vh; /* Full viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-video22 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Place video behind content */
}

/* Responsive adjustments */
.vertical-align-container {
  font-family: 'Macondo Swash Caps', cursive;
  font-size: 3.5rem;
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.left-aligned,
.right-aligned {
  width: 50%;
  text-align: center;
}

.right-aligned {
  text-align: right;
  padding-right: 2rem;
}

.left-aligned {
  padding-left: 4rem;
  text-align: left;
}

/* Media Queries */
@media (max-width: 767px) {
  .vertical-align-container {
    flex-direction: column;
    text-align: center;
  }

  .left-aligned,
  .right-aligned {
    text-align: center;
  }

  .right-aligned {
    padding-right: 0;
    padding-bottom: 2rem;
  }

  .left-aligned {
    padding-left: 0;
    padding-top: 2rem;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .vertical-align-container {
    flex-direction: column;
    text-align: center;
  }

  .left-aligned,
  .right-aligned {
    text-align: center;
  }

  .right-aligned {
    padding-right: 0;
    padding-bottom: 2rem;
  }

  .left-aligned {
    padding-left: 0;
    padding-top: 2rem;
  }
}

@media (min-width: 1024px) {
  .vertical-align-container {
    flex-direction: row;
  }
}

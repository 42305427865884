a{
    text-decoration: none;
}

.blog-container{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
    margin: 20px 0;
    justify-content: center;
    font-family: poppins;
}

.blog-box{
    width: 350px;
    background-color: #ffff;
    border: 1px solid #ececec;
    margin: 20px;
    box-shadow: 0px 0px 14px 6px rgba(0, 0, 0, 0.25);
    transition: all 0.3s ease;
}
.blog-box:hover{
    transform: scale(1.05);
}
.blog-box-slide:hover{
    transform: scale(1.05);
}
.blog-box-slide{
    width: 90%;
    transition: all 0.3s ease;
    background-color: #ffff;
    border: 1px solid #ececec;
    box-shadow: 0px 0px 14px 6px rgba(0, 0, 0, 0.25);
}

.blog-img{
    width: 100%;
    height: auto;
}

.blog-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.blog-text{
    padding: 30px;
    display: flex;
    flex-direction: column;
}

.blog-text .blogtitle{
    font-size: 1.3rem;
    font-weight: 500;
    color: #272727;
}
.blog-text .blogtitle:hover{
    color: rgba(52, 131, 201, 1);
    transition: all ease 0.3s;
}

.blog-text p{
    color: #9b9b9b;
    font-size: 0.9rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 20px 0;
}

.blog-text a{
    color: #0f0f0f;
}
.blog-text a:hover{
    color: rgba(52, 131, 201, 1);
    transition: all ease 0.3s;
}


.blog-btn{
    background: rgba(52, 131, 201, 1);
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    display: inline-block;
    margin-top: 28px;
    padding: 14px 22px;
    letter-spacing: 1px;
    border-radius: 10px;
    transition: all 0.3s ease;
}

.blog-btn:hover{
    background: rgb(39, 97, 147);
}
@media (max-width:565px){
    .blog-box{
        width:75%
    }
}
body,
html {
  height: 100%;
  width: 100%;
}

/* Remove the slider effect */
.slider, .slider1, .slider-container, .slider-slide {
  width: 100%; /* Make sure the width is 100% */
  height: auto; /* Set height to auto to avoid forced scrolls */
  overflow: visible; /* Remove overflow handling */
  box-shadow: none; /* Remove any box-shadow if applied */
  margin: 0 auto; /* Center the content */
}

/* Remove the horizontal scrollbar in .slider-image */
.slider-image {
  width: 100%; /* Set width to 100% for images */
  height: auto; /* Maintain aspect ratio */
  margin: 0; /* Remove margins */
  border-radius: 0; /* Remove any rounded borders */
}

/* Update .slider-content to take full width */
.slider-content {
  width: 100%; /* Ensure it takes up the full width */
  padding-top: 0; /* Remove extra padding */
  font-size: 1.1rem; /* Reduce font size if necessary */
}

/* Remove any padding or fixed width that forces a scroll */
.slider-container {
  padding-left: 0; /* Remove padding */
  padding-right: 0; /* Remove padding */
  max-width: none; /* Remove max-width to let it fill the space */
  overflow: visible; /* Remove overflow handling */
}

.content-container99, .slider-container, .sponsor-container {
  overflow: visible; /* Remove overflow */
  max-width: 100%; /* Ensure full width */
  flex-wrap: wrap; /* Allow wrapping of elements */
}

.bg {
  background-image: url(../../public/parliament.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  width: 100%;
  height: 94vh;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  justify-content: center;
  align-items: center;
  flex-flow:column;
  text-align: center;
  color: #ffffff;
}
.gallery-head{
  color: #000;
  font-family: Merriweather;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: underline;
    text-decoration-color: currentcolor;
  text-decoration-color: rgba(51, 107, 158, 1);
}
.heading {
  padding-top: 125px;
  text-align: justify;
  padding-left: 10%;
  padding-right: 10%;
  font-size: 5rem;
  color: white;
}
.quote {
  text-align: left;
  font-size: 1.25rem;
  color: white;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 10px;
}
/* Home.css */

.content-container99 {
  display: flex;
  flex-wrap: wrap;
}

.imag {
  width:40%; /* Take up available space */
  background-image: url("../../public/about.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: auto;
  border-radius: 18rem;
  
}
.imag img{
  width: -moz-available;
  height: -moz-available;
}

.text-container {
  width: 60%; /* Take up more space to accommodate text */
  padding: 20px; /* Add padding as needed */
  
}
.read-more-button {
  background-color: #007bff; /* Blue color, you can customize this */
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  /* padding: 10px 20px; */
  border: none; 
  cursor: pointer;
  width: 152px;
  height: 54px;
  flex-shrink: 0;
  border-radius: 12px;
  background: rgba(52, 131, 201, 1);
  margin-right: 61%;
  transition: all 0.3s ease;
}

.read-more-button:hover{
  background: rgb(39, 97, 147);
}
.about {
  padding-top: 60px;
  padding-bottom: 20px;
}
.about-title99 {
  color: #000;
  font-family: Merriweather;
  font-size: 45.08px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: underline;
  text-decoration-color: rgba(51, 107, 158, 1);
}
.recent-title {
  color: #000;
  font-family: Merriweather;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: underline;
    text-decoration-color: currentcolor;
  text-decoration-color: rgba(51, 107, 158, 1);
}
.about_heading {
  color: #0f0f0f;
  text-align: justify;
  font-family: Poppins;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 1.3;
  /* padding: 50px; */
  padding-bottom: 10px;
  width: 80%;
  height: auto;
  margin-right: auto;
  margin-left: auto;
  
}
.about_main {
 
  
  color: #565656;
  text-align: justify;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  /* line-height: 1.5; */
  /* padding: 20px;
    padding-top: 0px; */
  width: 80%;
  height: auto;
  margin-right: auto;
  margin-left: auto;
  padding-top: 10px;
}


/* Style for the image container */
.image-container {
  flex: 1; /* Allow image to take available space */
  padding: 10px; /* Add spacing as needed */
}

/* Style for the image */

.slider {
  /* padding-top: 50px; */
  width: 80%;
  height: auto;
  border-radius: 85px;
  background: #fff;
  /* box-sizing: border-box; */
  box-shadow: 0px 0px 14px 6px rgba(0, 0, 0, 0.19);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2.813rem;
}

.slider1 {
  /* padding-top: 50px; */
  width: 95%;
  height: auto;
  border-radius: 85px;
  background: #fff;
  /* box-sizing: border-box; */
  box-shadow: 0px 0px 14px 6px rgba(0, 0, 0, 0.19);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2.813rem;
}
/* Style the slider container */
.slider-container {
  position: relative;
  padding-left: 2.188rem;
  max-width: 95%; /* Optionally set a maximum width */
  height: auto; /* Set a fixed or percentage-based height */
  overflow: hidden; /* Hide any content that overflows */
 
}
.slider-slide {
  padding-right: 1.25rem; /* Add padding as needed */
}

.slider-image {
  float: left;
  width: 28%; /* Ensure the image fits within its container */
  height: auto; /* Maintain aspect ratio */
  border-radius: 20px;
  margin: 4.4rem 1.25rem;
}
.slider-image img{
  border-radius: 20px;
  width: 100%;
  height: 100%;
}
.slider-content {
  float: right;
  width:66%;
  padding-top: 2.5rem;
  
  color: #565656;
  text-align: justify;
  font-family: Poppins;
  font-size: 19px ;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5;
}
.slider-text {
  padding-top: 1.563rem;
}
.recent-activities {
  padding-top: 30px;
  
}


/* styles for sponsor */
.sponsor {
  width: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center; /* Vertically center align content */
  justify-content: space-between; /* Add space between the image and text */
  padding-right: 20px; /* Add padding as needed */
}

/* Style for the image */
.sponsor-image {
  float: left;
  width: -moz-available;
  width:-webkit-fill-available; /* Ensure the image fits within its container */
  height: -moz-available ;
  height:-webkit-fill-available; /* Maintain aspect ratio */
  margin: 20px;
}

/* Style the slider container */
.sponsor-container {
  position: relative;
  padding-left: 35px;
  height: auto; /* Set a fixed or percentage-based height */
  overflow: hidden; /* Hide any content that overflows */
}

.vector {
  width: 100%;
  height: 215px;
}



.blog-slider{
    background: #fff;
    margin: 0px 100px;
}
.slider-container-blog {
  position: relative;
  overflow: hidden; /* Hide any content that overflows */
  height: 780px;
}
.slick-list{
  overflow: scroll;
}
@media screen and (max-width: 1350px) {
  
  .about_heading{
    width:90%;
    font-size: 22px;
    line-height: 1.4;
  }
  .about_main{
    width: 90%;
    font-size: 18px;
  }
  .read-more-button{
    margin-right: 70%;
  }
}
@media screen and (max-width: 1176px) {
  
  .imag{
    width: 100%;
    height: fit-content;
  }
  .slider-content{
    font-size: large;
  }
  .imag img{
    padding-top: 20px;
    width: 70%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
  .text-container{
    padding-top: 10px;
    width: 100%;
  }
  .read-more-button{
    margin-right: 0%;
  }
}
@media screen and (max-width: 996px) {
  .slider {
    /* padding-top: 50px; */
    width: 60%;
    height: auto;
    border-radius: 85px;
    background: #fff;
    /* box-sizing: border-box; */
    box-shadow: 0px 0px 14px 6px rgba(0, 0, 0, 0.19);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2.813rem;
  }
  
  /* Style the slider container */
  .slider-container {
    position: relative;
    padding-left: auto;
    padding-right: auto;
    max-width: 95%; /* Optionally set a maximum width */
    height: auto; /* Set a fixed or percentage-based height */
    overflow: hidden; /* Hide any content that overflows */
   
  }
  .slider-slide {
    width: 100%; /* Ensure the slide takes up full container width */
    height: auto; /* Maintain aspect ratio */
    padding: 1rem; /* Add spacing inside the slide */
    display: flex; /* Enable flexible layout for slide content */
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    overflow: hidden; /* Hide any content that goes beyond bounds */
    box-sizing: border-box; /* Include padding and border in dimensions */
  }
  
  .slider-image {
  
    width: 100%; /* Ensure the image fits within its container */
    height: auto; /* Maintain aspect ratio */
    border-radius: 20px;
    margin: 2.2rem 0.2rem;
  }
  .slider-image img{
    border-radius: 25px;
    width: 100%;
    height: 100%;
  }
  .slider-content {
    
    width:100%;
    
    
    color: #565656;
    text-align: justify;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5;
  }
  .slider-text {
    padding-top: 0px;
  }
  .blog-slider{
    margin:0px 20px;
  }

}
@media screen and (max-width: 767px) {
  .slider {
    /* padding-top: 50px; */
    width: 90%;
    height: auto;
    
  }
  
  

}
@media screen and (max-width: 720px) {
  .heading {
    /* padding-top: 50px; */
   font-size: 4rem;
    
  }
  
  

}

@media screen and (max-width: 620px) {
  .heading {
    /* padding-top: 50px; */
   font-size: 3rem;
    
  }
  
  

}

@media screen and (max-width: 535px) {
 .blog-box{
  width:80%;
 }
 .blog-slider{
  margin:0px 30px;
 }
}
@media screen and (max-width: 435px) {
  .heading {
    /* padding-top: 50px; */
   font-size: 2.4rem;
    
  }
  .quote{
    font-size: 1rem;
  }
  
  

}


/* General Styling */


.theme-container21 {
  position: relative; /* Positioning for the pseudo-element */
  display: flex;
  flex-direction: row; /* Side-by-side layout */
  align-items: center;
  justify-content: space-around;
  min-height: 100vh;
  padding-left: 25px;
  overflow: hidden; /* Ensures no overflow from pseudo-element */
  margin: 0; /* Remove any gaps due to default margins */
}

.theme-container21:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('https://i.postimg.cc/j2ZV2zXJ/PC-25-web-banner.png') no-repeat center center fixed;
  background-size: cover;
  z-index: -1; /* Places it behind the content */
  border: none; /* Ensure no border around pseudo-element */
}

.computer-screen21 {
  width: 45%; /* Adjusted for side-by-side layout */
  aspect-ratio: 16 / 9;
  border: 10px solid #8c9c91;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}


.theme-video21 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.theme-text21 {
  width: 50%; /* Adjusted for side-by-side layout */
  text-align: left;
  background: rgba(0, 0, 0, 0.75); /* Slightly darker overlay */
  padding: 25px;
  padding-top: 30px;
  margin-right: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
}

.main-theme21 {
  font-size: 36px;
  font-weight: bolder;
  color: #ffd39d;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  font-family: Cantora One;
  margin-bottom: 1.25rem;
}

.sub-theme21 {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 1.5rem 0 0.5rem;
  color: #EEE4B1;
  font-family: Roboto;
}

.description21 {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  font-family: 'Roboto', sans-serif;
  text-align: justify;
  color: #e0e0e0;
  font-style: normal;
}

.why-epoch21 {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 1.5rem 0 0.5rem;
  color: #EEE4B1;
  font-family: Roboto;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .theme-container21 {
    flex-direction: column; /* Stack elements on smaller screens */
    align-items: center;
  }

  .computer-screen21 {
    width: 90%;
  }

  .theme-text21 {
    width: 90%;
    text-align: center;
  }

  .main-theme21 {
    font-size: 32px;
  }
}

@media (max-width: 768px) {
  .main-theme21 {
    font-size: 30px;
  }
  .theme-text21 {
    margin-top: 20px;
  }
}

@media (max-width: 480px) {
  .main-theme21 {
    font-size: 24px;
  }
  .theme-text21 {
    margin-top: 20px;
  }
}

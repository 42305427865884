.gallery-container{
    width: 100%;
    min-width: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 8%;
}

.gallery{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(250px,1fr));
    grid-gap: 30px;
}

.gallery img{
    width: 100%;
}

.gallery-head{
    color: #000;
    font-family: Merriweather;
    font-size: 45.08px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 91px;
    margin-bottom: -14px;
}


.grid-gallery {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 50px 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-auto-rows: 250px;
    grid-auto-flow: dense;
    grid-gap: 20px;
  }
  
  .grid-gallery .grid-item {
    position: relative;
    background-color: #efefef;
    overflow: hidden;
  }
  
  .grid-gallery .grid-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  
  .grid-gallery .grid-item:hover img {
    transform: scale(1.1);
  }
  
  .grid-gallery .grid-item a {
    cursor: zoom-in;
  }
  
  .grid-gallery .grid-item:nth-child(3n - 2) {
    grid-column: span 2;
    grid-row: span 2;
  }
  
  /* Let's make it responsive */
  @media (max-width: 768px) {
    .grid-gallery {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      grid-auto-rows: 250px;
    }
  
    .grid-gallery .grid-item:nth-child(3n - 2) {
      grid-column: unset;
      grid-row: unset;
    }
  }
.about {
    padding-top: 60px;
    padding-bottom: 20px;
  }
  
  .about-title99 {
    color: #000;
    font-family: Merriweather;
    font-size: 45.08px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration: underline;
    text-decoration-color: rgba(51, 107, 158, 1);
  }
  
  .content-container99 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .imag {
    background-image: url('https://i.postimg.cc/fbgsCSPB/about.jpg');
    background-size: cover;
    background-position: center;
    width: 45%;
    height: 300px;
    margin-right: 20px;
    border-radius: 8px;
  }
  
  .text-container {
    flex: 1;
    padding-left: 20px;
  }
  
  .about_heading {
    color: #0f0f0f;
    text-align: justify;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.3;
    padding-bottom: 10px;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
  }
  
  .about_main {
    color: #565656;
    text-align: justify;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    padding-top: 10px;
  }
  
  .read-more-button {
    background-color: #3491a3;
    color: #fff;
    font-family: Poppins;
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .read-more-button:hover {
    background-color: #287386;
  }
  
  @media screen and (max-width: 1250px) {
    .about_heading {
      width: 90%;
      font-size: 22px;
      line-height: 1.4;
    }
    .about_main {
      width: 90%;
      font-size: 18px;
    }
    .content-container99 {
      flex-direction: column;
    }
    .imag {
      display: none;
    }
  }
  
  @media screen and (max-width: 768px) {
    .about-title99 {
      font-size: 35px;
    }
    .about_heading {
      font-size: 20px;
    }
    .about_main {
      font-size: 16px;
    }
    .read-more-button {
      font-size: 14px;
    }
  }
  
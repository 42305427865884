.content a{
    text-decoration: none;
    color: #878787;
    font-size: 16px;
}
.content a:hover{
    color: #dcdcdc;
}
.footer {
    background-color:#1A2A41;
    font-family: merriweather;
    color: white;
}
.footer h4{
    color:aqua;
}
.col1{
    text-align: justify;
    margin-left: 180px;
    width: 110px;
}
.col2{
    text-align: justify;
    margin-left: 16%;
}
.col3{
    text-align: justify;
    margin-left: 20%;
    margin-right: 1rem;
}
.lines{
    width: 80%;
    margin: 12px 0;
}
.icon-container{
    display: flex;
    flex-direction:row;
    align-items: center;
    gap: 1.25rem;
    justify-content: center;
    margin-top: 1rem;
}
.p{
    margin-bottom: 0px;
}
.footer .title{
    width: 70%;
}


.content{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@media (max-width:870px){
    .content {
      flex-direction: column;
    }
    .col{
        text-align: center;
    }
    
    
    .col1, .col2, .col3{
        margin-right: 15%;
        margin-left: 15%;
    }
}




.modal-overlay11 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content11 {
  background-color: white;
  padding: 20px;
  position: relative;
}

.modal-image11 {
  max-width: 100%;
  max-height: 80vh;
}

.close-btn11 {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  background-color: #f44336;
  color: white;
  border: none;
  cursor: pointer;
  font-family: Roboto;
}


/* Merchandise container setup */
.merchandise-container11 {
  background: url('https://i.postimg.cc/j2ZV2zXJ/PC-25-web-banner.png') no-repeat center center fixed;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    height: 105vh;
    color: #4b3d33; /* Dark brown text color for coordination */
    font-family: 'Roboto Slab', serif;; /* Consistent font family */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
  }
  
  /* Merchandise Heading styles */
  .merchandise-heading11 {
    font-size: 3rem;
    font-weight: bolder;
    color: #ffd39d; /* Color from the general styling */
    font-family: Cantora One;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 1); /* Add text shadow for emphasis */
    text-transform: uppercase;
    margin-bottom: 40px;
  }
  
  /* Merchandise item layout */
  .merchandise-item11 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #998c7d; /* Light yellow background with opacity */
    border-radius: 10px;
    padding: 35px;
    width: 100%;
    max-width: 550px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  /* Image styling */
  .merchandise-image11 img {
    width: 100%;
    max-width: 400px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  /* Content styling */
  .merchandise-content11 {
    margin-top: 20px;
  }
  
  .merchandise-content11 p {
    font-size: 1.18rem;
    margin-bottom: 20px;
    color: #ffff;
    font-family: 'Roboto', sans-serif;
  }
  
  /* Button styling */
  .buy-now-btn11 {
    background-color: #5c4d3f; /* Dark brown button */
    color: white;
    padding: 15px 30px;
    font-size: 1.2rem;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s;
    margin-bottom: 20px;
  }
  
  .buy-now-btn11:hover {
    background-color: #8c7c6b; /* Lighter brown on hover */
  }
  
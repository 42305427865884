@import url('https://fonts.googleapis.com/css2?family=Gabarito:wght@500&family=Kanit&family=Lato&family=Roboto+Slab:wght@300&family=Ruwudu&display=swap');
h5{
    font-family: Gabarito , serif;
}
*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}


body, html {
    width: 100%;
}

/* The hero image */
.hero {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
   }
   .hero img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:17%;
    object-fit: cover;
   }
  .hero-text {
    width: 100%;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    z-index: 1;
    position: relative;
    flex-direction: column;
   }
   .hero .hero-text h1 {
    color: #FFF;
    font-size: 64px;
    
   }
   @import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");

   
   .container0 {
     display: flex;
     flex-wrap: wrap;
     justify-content: center;
     max-width: 1200px;
     margin-block: 2rem;
     gap: 3rem;
     margin-left: auto;
     margin-right: auto;
   }
   
   .card__image {
     max-width: 100%;
     display: block;
     object-fit: cover;
   }
   
   .cardmain {
    display: flex;
    flex-direction: column;
    width: clamp(20rem, calc(20rem + 2vw), 22rem);
    overflow: hidden;
    box-shadow: 0 .1rem 1rem rgba(0, 0, 0, 0.1);
    border-radius: 1em;
    background: #ECE9E6;
    background: linear-gradient(to right, #16435acc, #00344b);
    color: aliceblue;
    border: 1px solid #cccccc9a;
  padding: 10px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  text-align: left;
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: 0.375rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(0.375rem - 1px);
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(0, 0, 0, 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
   
   
   
   
   .card__body {
     padding: 1rem;
     display: flex;
     flex-direction: column;
     gap: .5rem;
   }
   
   
   .tag {
     align-self: flex-start;
     padding: .25em .75em;
     border-radius: 1em;
     font-size: .75rem;
   }
   
   .tag + .tag {
     margin-left: .5em;
   }
   
   .tag-blue {
     background: #56CCF2;
   background: linear-gradient(to bottom, #2F80ED, #56CCF2);
     color: #fafafa;
   }
   
   .tag-brown {
     background: #D1913C;
   background: linear-gradient(to bottom, #FFD194, #D1913C);
     color: #fafafa;
   }
   
   .tag-red {
     background: #cb2d3e;
   background: linear-gradient(to bottom, #ef473a, #cb2d3e);
     color: #fafafa;
   }
   
   .card__body h4 {
     font-size: 1.5rem;
     text-transform: capitalize;
   }
   
   .card__footer {
     display: flex;
     padding: 1rem;
     margin-top: auto;
   }
   
   .user {
     display: flex;
     gap: .5rem;
   }
   
   .user__image {
     border-radius: 50%;
   }
   
   .user__info > small {
     color: #666;
   }
   

.container1 {
    width: 1200px !important;
    padding: 0 !important;
    margin-right: auto;
    margin-left: auto;
  
    @media screen and (min-width: 992px) and (max-width: 1439px) {
      max-width: 1279px !important;
      padding: 0 !important;
      margin: 0 80px !important;
      width: auto !important;
    }
  
    @media screen and (max-width: 991px) {
      max-width: 959px !important;
      margin: 0 16px !important;
      padding: 0 !important;
      width: auto !important;
    }
  }
  
  .gradient-cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
    padding: 30px;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
    }
  }
  
  /* .container-title {
    text-align: center;
    padding: 0 !important;
    margin-bottom: 40px;
    font-size: 40px;
    color: #fff;
    font-weight: 600;
    line-height: 60px;
  } */
  
  .card1 {
    max-width: 550px;
    border: 0;
    width: 100%;
    margin-inline: auto;
  }
  
  .container-card {
    position: relative;
    border: 2px solid transparent;
    background: linear-gradient(71deg, #01202d, #010015cc, #002331);
    background-clip: padding-box;
    border-radius: 45px;
    padding: 40px;
    img {
      margin-bottom: 32px;
    }
  }
  
  .bg-green-box,
  .bg-white-box,
  .bg-yellow-box,
  .bg-blue-box {
    position: relative;
  }
  
  .bg-green-box::after,
  .bg-white-box::after,
  .bg-yellow-box::after,
  .bg-blue-box::after {
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    content: "";
    z-index: -1;
    border-radius: 45px;
  }
  
  .bg-green-box::after {
    background: linear-gradient(71deg, #0d1212, #3da077, #0d1212);
  }
  
  .bg-white-box::after {
    background: linear-gradient(71deg, #121013, #b0afb0, #121013);
  }
  
  .bg-yellow-box::after {
    background: linear-gradient(71deg, #110e0e, #afa220, #110e0e);
  }
  
  .bg-blue-box::after {
    background: linear-gradient(71deg, #0c0a0e, #5f6fad, #0c0a0e);
  }
  
  .card1-title {
    font-weight: 600;
    color: white;
    letter-spacing: -0.02em;
    line-height: 40px;
    font-style: normal;
    font-size: 28px;
    padding-bottom: 8px;
  }
  
  .card-description {
    font-weight: 600;
    line-height: 32px;
    color: hsla(0, 0%, 100%, 0.652);
    font-size: 16px;
    max-width: 470px;
  }
  
.past-speakers-PCo {
  background: url('https://i.postimg.cc/j2ZV2zXJ/PC-25-web-banner.png') no-repeat center center;
  background-size: cover;
  background-attachment: fixed;
  padding: 40px 20px;
  text-align: center;
  position: relative;
  z-index: 1;
}

.past-speakers-heading-PCo {
  font-size: 3rem;
  font-weight: bolder;
  color: #ffd39d;
  font-family: Cantora One;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
  text-transform: uppercase;
  margin-bottom: 40px;
  text-align: center;
}

.cards-PCo {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  max-width: 1200px;
  margin: auto;
}

.card-PCo {
  position: relative;
  overflow: hidden;
  padding: 5px;
  background: none;
  border: 1px solid;
  border-radius: 8px;
  border-width: 3px;
  border-color: #c5ad85;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.speaker-img-PCo {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
  border-radius: 8px;
}

.overlay-PCo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  opacity: 0;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: opacity 0.3s ease-in-out;
}

.card-PCo:hover .overlay-PCo {
  opacity: 1;
}

.card-PCo:hover .speaker-img-PCo {
  transform: scale(1.05);
}

.info-PCo h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.info-PCo p {
  font-size: 16px;
}

@media (max-width: 1024px) {
  .cards-PCo {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .cards-PCo {
    grid-template-columns: 1fr;
  }
}

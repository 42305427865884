.page h1{
 color:rgb(249, 249, 249);
}

.bg-image h5{
    color:rgb(166, 183, 204);
    margin-top:20px;
}
.page{
    font-family:merriweather;
}
.card{
    background-color: rgb(37, 63,86);
    max-width: 400px;
}
.content{
    display: flex;
    flex-direction: row;
}

/* CSS */
.button {
  appearance: button;
  backface-visibility : hidden;
  background-color: #1a8abe;
  border-radius: 6px;
  border-width: 0;
  box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset,rgba(50, 50, 93, .1) 0 2px 5px 0,rgba(0, 0, 0, .07) 0 1px 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  font-family: -apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Ubuntu,sans-serif;
  font-size: 100%;
  height: 44px;
  line-height: 1.15;
  margin: 12px 0 0;
  outline: none;
  overflow: hidden;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-transform: none;
  transform: translateZ(0);
  transition: all .2s,box-shadow .08s ease-in;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
}

.button:disabled {
  cursor: default;
}

.button:focus {
  box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset, rgba(50, 50, 93, .2) 0 6px 15px 0, rgba(0, 0, 0, .1) 0 2px 2px 0, rgba(50, 151, 211, .3) 0 0 0 4px;
}

@media (max-width:500px){
    .content {
      flex-direction: column;
    }
}

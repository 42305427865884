/* Background Image with Darker Overlay */
.background-image112 {
    background-image: url('https://i.postimg.cc/j2ZV2zXJ/PC-25-web-banner.png');
    background-size: cover; /* Ensures the image covers the entire section */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    position: relative; /* Allows positioning of the overlay */
    width: 100%; /* Ensures it spans the entire width */
    height: auto; /* Adjusts height dynamically */
    min-height: 100vh; /* Ensures the section is at least full viewport height */
    overflow: hidden; /* Prevents overflow */
    background-attachment: fixed; /* Fixes the background during scrolling */
}


.background-image112::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75); /* Darker overlay with 75% opacity */
    z-index: 1; /* Places the overlay below text but above the background image */
    opacity: 1; /* Ensure the overlay is visible */
}

/* Heading Styling */
.phead12 {
    font-size: 48px; /* Larger font size for prominence */
    font-weight: 900; /* Extra bold font */
    color: #FFD700; /* Bright gold color for prominence */
    font-family: 'Roboto Slab', serif; /* Professional serif font */
    text-align: center; /* Center the heading */
    margin-top: 50px; /* Increased margin from the top of the page */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Subtle shadow for better readability */
}



.psection12 {
    padding: 50px 10px; /* Add horizontal padding for smaller screens */
    background-color: #f4f4f4;
    overflow: hidden; /* Prevent any overflow */
}

/* Remove margins and paddings from body and html */
html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden; /* Prevent horizontal overflow */
}

.psection12 {
    padding: 0; /* Remove padding from the section */
    margin: 0; /* Remove any margin */
    background-color: #f4f4f4;
    overflow: hidden; /* Prevent any overflow */
}

/* Create an overlay for opacity control */
.background-image112::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Black overlay with 60% opacity */
    z-index: 1; /* Places the overlay below text but above the background image */
}

/* Ensure text and content are above the overlay */
.text-center12,
.section-wrapper12 {
    position: relative;
    z-index: 2;
    color: white; /* Adjust text color for better contrast with the background */
}

.background-image112 {
    background-image: url('https://i.postimg.cc/j2ZV2zXJ/PC-25-web-banner.png');
    background-size: cover; /* Ensures the image covers the entire section */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    position: relative; /* Allows positioning of the overlay */
    width: 100%; /* Ensures it spans the entire width */
    height: auto; /* Adjusts height dynamically */
    min-height: 100vh; /* Ensures the section is at least full viewport height */
    overflow: hidden; /* Prevents overflow */
}

/* Create an overlay for opacity control */
.background-image112::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Black overlay with 60% opacity */
    opacity: 0;
    z-index: 1; /* Places the overlay below text but above the background image */
}

/* Ensure text and content are above the overlay */
.text-center12,
.section-wrapper12 {
    position: relative;
    z-index: 2;
}


.phead12 {
    font-size: 48px;
    font-weight: bolder;
    color: #ffd39d;
    font-family: Cantora One;
    text-align: center; /* Center the heading */
    padding-top: 25px;
}

.text-center12 {
    text-align: center;
}

.section-wrapper12 {
    display: flex;
    justify-content: center; /* Center the cards horizontally */
    gap: 30px; /* Increased gap between cards in normal screen view */
    flex-wrap: wrap; /* Ensure the cards wrap when the screen is small */
    margin-top: 50px;
    width: 100%;
    box-sizing: border-box; /* Prevent cards from overflowing */
}

.section-card12 {
    background: linear-gradient(145deg, #f4f4f4, #e0e0e0); /* Fancy gradient background */
    border-radius: 12px; /* Rounded corners */
    overflow: hidden;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Smooth, large shadow for a floating effect */
    width: 100%; /* Full width by default */
    max-width: 350px; /* Prevent card from exceeding 300px */
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background 0.3s ease-in-out;
    cursor: pointer; /* Change cursor on hover to indicate interactivity */
    margin-bottom: 20px; /* Add space between cards */
    box-sizing: border-box; /* Prevent any padding from affecting width */
}

.section-card12:hover {
    transform: translateY(-20px); /* Lift the card more on hover */
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3); /* More prominent shadow on hover */
    background: linear-gradient(145deg, #e0e0e0, #f4f4f4); /* Reverse gradient for hover effect */
}

.section-image12 {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-bottom: 2px solid #ccc; /* Add border for image separation */
}

.section-text12 {
    padding: 20px;
}

.section-text12 h3 {
    font-size: 24px;
    color: #333;
    margin-bottom: 15px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Use modern font */
}

.section-text12 p {
    font-size: 16px;
    color: #555;
    line-height: 1.5;
    font-family: 'Roboto', sans-serif;
    text-align: justify; /* Justify text alignment */
}

/* Responsive styles for smaller screens */
@media (max-width: 1024px) {
    .section-card12 {
        width: 30%; /* Reduce width to 30% to fit 3 cards in one row */
        margin-bottom: 20px; /* Slight space between cards on medium screens */
    }

    .section-wrapper12 {
        gap: 20px; /* Moderate gap between cards */
    }

    .phead12{
        font-size: 48px;
    }
}

/* Responsive styles for smaller screens (Mobile phones and below) */
@media (max-width: 768px) {
    .section-card12 {
        width: 45%; /* Cards take up 45% of the width on smaller screens */
        margin-bottom: 15px; /* Reduce the gap between cards */
    }

    .section-wrapper12 {
        gap: 15px; /* Reduce the gap between cards */
    }
    .phead12{
        font-size: 35px;
    }
}

/* Responsive styles for mobile devices */
@media (max-width: 480px) {
    .section-wrapper12 {
        flex-direction: column; /* Stack cards vertically on mobile screens */
        align-items: center; /* Center the cards */
        gap: 10px; /* Reduce the gap between stacked cards */
    }

    .section-card12 {
        width: 90%; /* Cards take up 90% of the width on mobile devices */
        margin-bottom: 20px; /* Add margin to the bottom of cards for spacing */
    }

    .phead12{
        font-size: 35px;
    }
    
}
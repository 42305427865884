.content33 a {
    text-decoration: none;
    color: #878787;
    font-size: 16px;
}

.content33 a:hover {
    color: #dcdcdc;
}

.footer33 {
    background-color: #ca7968;
    background-image: linear-gradient(315deg, #ca7968 0%, #0c0c0c 0%);
    font-family: merriweather;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 250px; /* Set height to create space for content */
    position: relative; /* Ensure that the child elements can be absolutely positioned within the footer */
}

.footer33 h4 {
    color: aqua;
}

.col133 {
    text-align: justify;
    margin-left: 180px;
    width: 110px;
}

.col233 {
    text-align: justify;
    margin-left: 10%;
}

.col333 {
    text-align: justify;
    margin-left: 10%;
    margin-right: 1rem;
}

.lines33 {
    width: 80%;
    margin: 12px 0;
}

.icon-container33 {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.25rem;
    justify-content: flex-start;
    margin-bottom: 5px;
    margin-left: 20px;
}

.p33 {
    position: absolute;
    bottom: 10px; /* Position at the bottom of the footer */
    right: 10px;  /* Position on the right side */
    margin-bottom: 0px;
    color: white;
}

.footer33 .title33 {
    width: 100%;
}

.content33 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start; /* Align content at the top */
    gap: 20px; /* Add space between columns */
}

.logo-container33 {
    margin-left: 20px; /* Adjust spacing for the logo */
    display: flex;
    align-items: center;
}

.logo33 {
    width: 200px; /* Increase width of the logo */
    height: auto; /* Maintain aspect ratio */
    margin-right: 0px; /* Add spacing between the logo and content */
}

.footer33 .bottom-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

@media (min-width: 1200px) {
    .logo33 {
        width: 200px; /* Larger logo for bigger screens */
    }
}

@media (max-width: 936px) {
    .footer33 {
        height: auto; /* Adjust height dynamically */
        padding: 10px; /* Add some padding */
    }

    .content33 {
        flex-direction: row; /* Keep row alignment */
        justify-content: center; /* Center content */
        align-items: flex-start; /* Align items at the top */
        gap: 15px; /* Add slight spacing between columns */
    }

    .col133, .col233, .col333 {
        text-align: justify; /* Keep text justified */
        margin-left: 5%; /* Reduce left margin */
        margin-right: 5%; /* Add right margin */
        width: auto; /* Adjust width dynamically */
    }

    .logo33 {
        width: 150px; /* Shortened logo size */
        height: auto; /* Maintain aspect ratio */
        margin-right: 15px; /* Add spacing after the logo */
    }

    .icon-container33 {
        gap: 10px; /* Reduce spacing between icons */
    }

    .icon-container33 img {
        width: 28px; /* Slightly smaller icons */
        height: 28px;
    }

    .p33 {
        font-size: 14px; /* Slightly smaller footer text */
        margin-top: 5px; /* Reduce spacing */
    }
}
@media (max-width: 775px) {
    .footer33 {
        height: auto; /* Adjust height dynamically */
        padding: 10px; /* Add padding */
    }

    .content33 {
        flex-direction: row; /* Maintain row alignment */
        justify-content: center; /* Center-align content */
        align-items: flex-start; /* Align items at the top */
        gap: 10px; /* Reduce spacing between columns */
    }

    .col133, .col233, .col333 {
        text-align: justify; /* Keep text justified */
        margin-left: 3%; /* Narrower left margin */
        margin-right: 3%; /* Narrower right margin */
        width: auto; /* Allow flexible width */
    }

    .logo33 {
        width: 120px; /* Shortened logo size */
        height: auto; /* Maintain aspect ratio */
        margin-right: 10px; /* Reduce spacing after the logo */
    }

    .icon-container33 {
        gap: 8px; /* Further reduce spacing between icons */
    }

    .icon-container33 img {
        width: 24px; /* Smaller icon size */
        height: 24px;
    }

    .p33 {
        font-size: 12px; /* Smaller footer text */
        margin-top: 5px; /* Reduce vertical margin */
    }
}
@media (max-width: 490px) {
    .footer33 {
        height: auto; /* Adjust height dynamically */
        padding: 10px; /* Add padding for smaller screens */
    }

    .content33 {
        flex-direction: column; /* Stack content vertically */
        justify-content: center; /* Center align content */
        align-items: center; /* Center align items */
        gap: 15px; /* Add spacing between sections */
    }

    .logo33 {
        display: none; /* Remove the logo on small screens */
    }

    .col133, .col233, .col333 {
        text-align: center; /* Center-align text */
        margin-left: 0; /* Remove left margin */
        margin-right: 0; /* Remove right margin */
        width: 90%; /* Reduce column width to fit smaller screens */
    }

    .icon-container33 {
        justify-content: center; /* Center align icons */
        gap: 10px; /* Adjust spacing between icons */
    }

    .icon-container33 img {
        width: 20px; /* Reduce icon size */
        height: 20px;
    }

    .p33 {
        display: none; /* Remove the copyright line */
    }
}

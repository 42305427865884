/* General Container */
.section2-container-PoCo {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f5f1e6; /* Light beige text for contrast */
}

/* Background Video */
.background-video-PoCo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.6;
  z-index: 1;
}

/* Content Overlay */
.section2-content-PoCo {
  position: relative;
  z-index: 2;
  max-width: 85%;
  text-align: justify;
  background: rgba(63, 42, 20, 0.9);
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
}

/* Title Styling */
.section2-title-PoCo {
  font-family: 'Merriweather', serif;
  font-size: 2.8rem;
  margin-bottom: 20px;
  color: #d4a373;
  text-align: center;
}

/* Paragraph Styling */
.section2-text-PoCo {
  font-size: 1rem;
  font-family: 'Roboto', sans-serif;
  line-height: 1.8;
  margin: 15px 0;
  color: #f5f1e6;
}

/* List Styling */
.section2-list-PoCo {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  margin: 20px auto;
  list-style-type: none;
  line-height: 1.8;
  text-align: justify;
  color: #f5f1e6;
}

.section2-list-PoCo li {
  margin-left: 20px;
  position: relative;
  padding-left: 20px;
}

.section2-list-PoCo li::before {
  content: "\2022";
  color: #d4a373;
  font-size: 1.5rem;
  position: absolute;
  left: 0;
  top: 0;
}

/* Responsive Design */
@media (max-width: 1023px) {
  .section2-content-PoCo {
    max-width: 85%;
    padding: 20px;
  }

  .section2-title-PoCo {
    font-size: 2.5rem;
  }

  .section2-text-PoCo,
  .section2-list-PoCo {
    font-size: 1rem;
  }
}

@media (max-width: 670px) {
  .section2-content-PoCo {
    max-width: 90%;
    padding: 15px;
  }

  .section2-title-PoCo {
    font-size: 2rem;
  }

  .section2-text-PoCo,
  .section2-list-PoCo {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .section2-content-PoCo {
    max-width: 95%;
    padding: 10px;
  }

  .section2-title-PoCo {
    font-size: 1.8rem;
  }

  .section2-text-PoCo,
  .section2-list-PoCo {
    font-size: 0.8rem;
    line-height: 1.5;
  }
}

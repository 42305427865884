/* Navbar.css */
/* dl,ol,ul {
  margin-top: 0;
  margin-bottom: 0;
} */
/* 
header{
  transition: 0.6s ease;
  box-shadow: 0px 0px 20px rgb(0 0 0 / 10%);
  background-image: url(../../public/parliament.jpg);
  position: fixed;
  max-width: 1360px;
}

header .nav-bar{
  height: calc(2.5rem + 1.8rem);
}


.nav-bar{
  height: calc(4rem + 1rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 35px;
  margin-right: 35px;
  padding: 0 20px;
  transition: 0.6s ease;
}

.nav-bar .logo img{
  width: 30%;
  margin: 5px 10px;
}

.nav-items a{
  text-decoration: none;
  color: black;
  font-size: 20px;
  font-weight: 600;
}

.nav-items a:hover{
  color: var(--second-color);
}

.nav-items a:not(:last-child){
  margin-right: 50px;
} */

@import url('https://fonts.googleapis.com/css2?family=Poppins&family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700;1,900&display=swap');


#menubar {
  background-color: rgb(255, 255, 255) !important;
  /* background-image: url(../../public/parliament.jpg); */
  box-shadow: 0px 0px 20px rgb(0 0 0 / 10%);
}


.navbar-brand {
  display: flex;
  align-items: center;
}

.navaa {
  padding: 0px 30px;
  font-size: 17px;
  font-family: 'Poppins', sans-serif;
}

.iitk{
  height: 48px;
  margin-right: 8px;
}
#ppoc-logo {
  height: 45px;
}